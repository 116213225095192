const degrees = [
  {
    school: 'Islamic Azadi University',
    degree: 'B.S. Computer Engineering',
    link: 'https://khuisf.ac.ir',
    year: 2019,
  },
];

export default degrees;
